import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Update, Cancel } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { questionOperations } from '../../state/ducks/question';
import { masterOperations } from '../../state/ducks/master';
import { categoryOperations } from '../../state/ducks/category';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as API_URL from "../../env.js";
import request from "../../utils/request";

class QuestionEdit extends React.Component {

  constructor() {
    super();
    this.state = {
      category: [],
      language: [],
      selectedQuestionData: {}
    }
  }

  async componentDidMount() {
    try {
      this.loadLocalStorageData();
      const questionId = localStorage.getItem("selectedQuestionId");
      const options = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/question/get-Question-details/${questionId}`,
        method: "GET",
        headers: {
          authorization: localStorage.getItem('token')
        }
      };
      let response = await request(options);
      this.setState({ selectedQuestionData: response.payload });
    } catch (err) {
      console.log(err);
    }
  }

  loadLocalStorageData = async () => {
    //Store category
    let category = localStorage.getItem('category');
    if (category) {
      category = JSON.parse(category);
      this.setState({ category });
    } else {
      let response = await this.props.listCategory();
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload);
        localStorage.setItem('category', searchString);
        category = JSON.parse(searchString);
        this.setState({ category });
      }
    }
    // Store Language
    let language = localStorage.getItem('language');
    if (language) {
      language = JSON.parse(language);
      this.setState({ language });
    } else {
      let response = await this.props.listLanguage();
      if (response.payload.length !== 0) {
        const languages = JSON.stringify(response.payload);
        localStorage.setItem('language', languages);
        language = JSON.parse(languages);
        this.setState({ language });
      }
    }
  }

  handleInput = async (e) => {
    try {
      const { selectedQuestionData } = this.state;
      if (e.target === undefined) {
        const question_description = e;
        this.setState({
          selectedQuestionData: {
            ...selectedQuestionData,
            question_description
          }
        });
      }
      else {
        let tid = e.target.id;
        switch (tid) {
          case 'question':
            const question = e.target.value;
            this.setState({
              selectedQuestionData: {
                ...selectedQuestionData,
                question
              }
            });
            break;
          case 'question_status':
            const question_status = e.target.value;
            this.setState({
              selectedQuestionData: {
                ...selectedQuestionData,
                question_status
              }
            });
            break;
          case 'category_id':
            const category_id = e.target.value;
            this.setState({
              selectedQuestionData: {
                ...selectedQuestionData,
                category_id
              }
            });
            break;
          case 'question_language':
            const question_language = e.target.value;
            this.setState({
              selectedQuestionData: {
                ...selectedQuestionData,
                question_language
              }
            });
            break;
          case 'question_notes':
            const question_notes = e.target.value;
            this.setState({
              selectedQuestionData: {
                ...selectedQuestionData,
                question_notes
              }
            });
            break;
          default:
            console.log("No Matching target found in handleInput");
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedQuestionData } = this.state;
      let isStatus;
      if (selectedQuestionData.question_status === 'active') {
        isStatus = 1;
      } else if (selectedQuestionData.question_status === 'inactive') {
        isStatus = 0;
      }
      let updateResponse = await this.props.updateQuestion({
        id: selectedQuestionData.id,
        question: selectedQuestionData.question,
        status: isStatus,
        category_id: selectedQuestionData.category_id,
        question_language: selectedQuestionData.question_language,
        question_description: selectedQuestionData.question_description,
        question_notes: selectedQuestionData.question_notes
      });
      toast.success(updateResponse.message, { autoClose: 10000 });
      if (localStorage.getItem("selectedCategoryId")) {
        window.open("/question-category/category-details/", "_self");
      }
      else {
        window.open("/question-category/question/", "_self");
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  render() {
    const { category, language, selectedQuestionData } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/question-category/question/">Question Category</Link>
                </li>
                <li className='breadcrumb-item active'>Edit Question</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className='edit-profile'>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Edit Question</H5>
                  </CardHeader>
                  <Form onSubmit={this.onUpdate}>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Edit Question</Label>
                            <Input type="text" name="question" id="question" placeholder="Enter Question" value={selectedQuestionData.question || ''} onChange={this.handleInput} />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label className="form-label">Question Status</Label>
                            <Input type="select" name="question_status" id="question_status" value={selectedQuestionData.question_status || ''} onChange={this.handleInput}>
                              <option value=''>Select Status</option>
                              <option value='active'>Active</option>
                              <option value='inactive'>Inactive</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label className="form-label">Assosicated Category</Label>
                            <Input type="select" name="category_id" id="category_id" value={selectedQuestionData.category_id || ''} onChange={this.handleInput}>
                              <option value=''>Select Category</option>
                              {category.map((data, i) =>
                                <option key={i} value={data.id}>{data.category_name}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label className="form-label">Question Language</Label>
                            <Input type="select" name="question_language" id="question_language" value={selectedQuestionData.question_language || ''} onChange={this.handleInput}>
                              <option value=''>Select Language</option>
                              {language.map((data, i) =>
                                <option key={i} value={data.language_name}>{data.language_name}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Question Description</Label>
                            <ReactQuill
                              id="question_description"
                              name="question_description"
                              value={selectedQuestionData.question_description || ''}
                              onChange={this.handleInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Question Notes(Internal)</Label>
                            <Input type="textarea" placeholder="Enter Question Notes" id="question_notes" name="question_notes" value={selectedQuestionData.question_notes || ''} onChange={this.handleInput} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                      <Link to="/question-category/question/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment >
    )
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  updateQuestion: questionOperations.updateQuestion,
  listCategory: categoryOperations.listCategory,
  listLanguage: masterOperations.listLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEdit)